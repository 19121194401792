import React from 'react';
import { Link } from 'react-router-dom';
import { Instagram, MapPin, QrCode, ShieldCheck } from 'lucide-react';

const Footer = () => {
  const handleAddressClick = () => {
    window.open('https://maps.google.com/?q=500+Biscayne+Dr+West+Palm+Beach+FL+33401', '_blank');
  };

  return (
    <footer className="bg-white border-t border-gray-200 pt-16 pb-8">
      <div className="max-w-[1800px] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          <div>
            <ul className="space-y-2">
              <li>
                <a href="mailto:showponysales@gmail.com" className="text-gray-600 hover:text-black">
                  Contact
                </a>
              </li>
              <li>
                <Link to="/shipping" className="text-gray-600 hover:text-black">
                  Shipping
                </Link>
              </li>
              <li>
                <Link to="/contact-card" className="flex items-center gap-2 text-gray-600 hover:text-black">
                  <QrCode className="h-4 w-4" />
                  Contact Card
                </Link>
              </li>
              <li>
                <Link to="/admin/login" className="flex items-center gap-2 text-gray-600 hover:text-black">
                  <ShieldCheck className="h-4 w-4" />
                  Admin Portal
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-medium mb-4">Visit Us</h3>
            <button 
              onClick={handleAddressClick}
              className="not-italic text-gray-600 hover:text-black text-left flex items-start gap-2 group"
            >
              <MapPin className="h-5 w-5 mt-0.5 flex-shrink-0 group-hover:text-black" />
              <div>
                <p>500 Biscayne Dr.</p>
                <p>West Palm Beach, FL 33401</p>
                <p className="text-sm text-gray-500 group-hover:text-black mt-1">Open in Google Maps</p>
              </div>
            </button>
            <div className="mt-4">
              <p className="font-medium">Hours:</p>
              <p>Monday - Saturday</p>
              <p>10:00 AM - 4:00 PM</p>
            </div>
          </div>

          <div>
            <h3 className="text-lg font-medium mb-4">Connect</h3>
            <div className="space-y-2">
              <div>
                <a
                  href="https://www.instagram.com/showponypalmbeach/?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 text-gray-600 hover:text-black"
                >
                  <Instagram className="h-5 w-5" />
                  Instagram
                </a>
              </div>
              <div>
                <a
                  href="https://www.chairish.com/shop/showpony"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-black"
                >
                  Shop on Chairish
                </a>
              </div>
              <div>
                <a
                  href="https://www.1stdibs.com/dealers/show-pony-palm-beach/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-black"
                >
                  Shop on 1stDibs
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-12 pt-8 border-t border-gray-200 text-center text-gray-600">
          <p>© {new Date().getFullYear()} SHOW PONY PALM BEACH. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;