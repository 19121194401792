import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { Cart } from '../cart';
import { useScrollBehavior } from '../../lib/hooks/useScrollBehavior';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [isCartOpen, setIsCartOpen] = React.useState(false);
  useScrollBehavior();

  return (
    <div className="min-h-screen flex flex-col">
      <div className="fixed w-full top-0 z-50">
        <Navbar onCartClick={() => setIsCartOpen(true)} />
      </div>
      
      <Cart isOpen={isCartOpen} onClose={() => setIsCartOpen(false)} />
      
      <main className="flex-grow pt-16">
        {children}
      </main>
      
      <Footer />
    </div>
  );
};

export default Layout;