import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '../lib/utils/hooks/useMediaQuery';
import CategoryNav from './layout/CategoryNav';

const heroImages = [
  'https://res.cloudinary.com/graphichut/image/upload/v1742557256/white_shirt_cja82i.jpg',
  'https://res.cloudinary.com/graphichut/image/upload/v1742562043/tank_shirt_oonzfv.jpg',
  'https://res.cloudinary.com/graphichut/image/upload/v1742760889/white_shirt2_nxcyfv.jpg'
];

const Hero = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleImageClick = (e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault(); // Prevent default behavior
    setCurrentIndex(prevIndex => (prevIndex + 1) % heroImages.length); // Cycle through images
  };

  const handleExplore = (e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault(); // Prevent default behavior
    e.stopPropagation(); // Prevent triggering image switch
    navigate('/shop');
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  };

  const handleTrunkClick = (e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();
    window.open('https://showponygallery.com', '_blank');
  };

  return (
    <div className="space-y-0">
      <div className="relative w-full">
        <div 
          className="relative w-full" 
          style={{ paddingBottom: '65%' }}
          onClick={handleImageClick}
          onTouchEnd={handleImageClick}
          role="button"
          tabIndex={0}
        >
          <img
            src={heroImages[currentIndex]}
            alt="Elegant vintage furniture display"
            className="absolute inset-0 w-full h-full object-cover object-center select-none"
            loading="eager"
            priority="high"
            draggable={false}
          />
          {/* Gallery link - only show on third image */}
          {currentIndex === 2 && (
            <button 
              onClick={handleTrunkClick}
              onTouchEnd={handleTrunkClick}
              className="absolute left-[5%] bottom-[14%] cursor-pointer z-10 bg-black/50 backdrop-blur-sm px-4 py-2 rounded-md opacity-0 hover:opacity-100 transition-opacity"
            >
              <span className="text-white font-medium text-lg">Visit Gallery</span>
            </button>
          )}
          <div className={`absolute inset-0 flex items-end justify-center ${isMobile ? 'pb-12' : 'pb-16'}`}>
            <button 
              onClick={handleExplore}
              onTouchEnd={handleExplore}
              className="bg-white/50 backdrop-blur-sm text-gray-800 px-8 py-3 text-lg rounded-md hover:bg-white/60 transition-colors transform hover:scale-105 duration-200"
            >
              {isMobile ? 'Browse Collection' : 'Explore Collection'}
            </button>
          </div>
        </div>
      </div>
      
      <div className="bg-black">
        <CategoryNav />
      </div>
    </div>
  );
};

export default Hero;