import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase/client';
import type { Product } from '../lib/types/product';
import OptimizedImage from './ui/OptimizedImage';

// Get a deterministic seed based on the current date
const getDailySeed = () => {
  const today = new Date();
  return today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
};

// Fisher-Yates shuffle with seed
const seededShuffle = (array: any[], seed: string) => {
  const shuffled = [...array];
  const seedNumber = Array.from(seed).reduce((acc, char) => acc + char.charCodeAt(0), 0);
  let currentIndex = array.length;

  while (currentIndex > 0) {
    const randomIndex = Math.floor(
      ((seedNumber * currentIndex) % array.length + currentIndex) % currentIndex
    );
    currentIndex--;
    [shuffled[currentIndex], shuffled[randomIndex]] = [
      shuffled[randomIndex],
      shuffled[currentIndex]
    ];
  }

  return shuffled;
};

const FeaturedProducts = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        // Fetch all published products
        const { data, error: fetchError } = await supabase
          .from('products')
          .select('*')
          .eq('status', 'published')
          .order('created_at', { ascending: false });

        if (fetchError) throw fetchError;
        if (!data) throw new Error('No products found');

        // Get today's seed and shuffle products
        const seed = getDailySeed();
        const shuffledProducts = seededShuffle(data, seed);
        
        // Take first 12 products
        setProducts(shuffledProducts.slice(0, 12));
        setError(null);
      } catch (error) {
        console.error('Error fetching featured products:', error);
        setError('Failed to load featured products');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleProductClick = (productId: string) => {
    window.scrollTo({ top: 0, behavior: 'instant' });
    navigate(`/product/${productId}`);
  };

  if (error || (!loading && !products.length)) return null;

  if (loading) {
    return (
      <div className="max-w-[1800px] mx-auto px-4 sm:px-6 lg:px-8 pt-4 pb-16">
        <h2 className="text-3xl mb-12 text-center">Featured Pieces</h2>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8">
          {[...Array(12)].map((_, i) => (
            <div key={i} className="relative pb-[133.33%] bg-gray-100 rounded-lg animate-pulse" />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-[1800px] mx-auto px-4 sm:px-6 lg:px-8 pt-4 pb-16">
      <h2 className="text-3xl mb-12 text-center">Featured Pieces</h2>
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8">
        {products.map((product) => (
          <button
            key={product.id}
            onClick={() => handleProductClick(product.id)}
            className="relative w-full bg-gray-100 rounded-lg overflow-hidden group"
          >
            {/* 4:3 aspect ratio container */}
            <div className="relative pb-[133.33%]">
              <OptimizedImage
                src={product.photos[0]}
                alt={product.title}
                className="absolute inset-0 w-full h-full object-contain transition-transform duration-300 group-hover:scale-105"
                sizes="(min-width: 1280px) 33vw, (min-width: 768px) 50vw, 100vw"
              />
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default FeaturedProducts;