import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

// Use a singleton pattern to ensure only one client instance
let supabaseInstance: ReturnType<typeof createClient<Database>> | null = null;

export const getSupabaseClient = () => {
  if (!supabaseInstance) {
    supabaseInstance = createClient<Database>(supabaseUrl, supabaseAnonKey, {
      auth: {
        persistSession: true,
        storageKey: 'show-pony-auth',
        autoRefreshToken: true,
        detectSessionInUrl: true
      },
      global: {
        headers: { 'x-client-info': 'show-pony-palm-beach' }
      }
    });
  }
  return supabaseInstance;
};

export const supabase = getSupabaseClient();

// Add connection health check
let isHealthy = true;

supabase.from('products').select('id', { count: 'exact', head: true })
  .then(() => { isHealthy = true; })
  .catch(() => { isHealthy = false; });

export const isSupabaseHealthy = () => isHealthy;