import React, { useState } from 'react';
import { cn } from '../../utils/cn';

interface OptimizedImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  alt: string;
  sizes?: string;
  className?: string;
  priority?: boolean;
}

const OptimizedImage: React.FC<OptimizedImageProps> = ({
  src,
  alt,
  sizes = '100vw',
  className,
  priority = false,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(true);

  // Generate optimized Cloudinary URL with quality and format optimization
  const optimizedSrc = src.includes('cloudinary') 
    ? src.replace(/upload\//, 'upload/f_auto,q_auto,dpr_auto,c_limit/')
    : src;

  // Generate smaller thumbnail URL for blur placeholder
  const thumbnailUrl = src.includes('cloudinary')
    ? src.replace(/upload\//, 'upload/w_50,e_blur:1000,q_1,f_auto/')
    : src;

  // Generate srcSet for responsive images
  const srcSet = src.includes('cloudinary')
    ? [320, 480, 640, 768, 1024, 1280, 1536, 1920]
        .map(width => {
          const optimizedUrl = src.replace(/upload\//, `upload/w_${width},f_auto,q_auto/`);
          return `${optimizedUrl} ${width}w`;
        })
        .join(', ')
    : undefined;

  return (
    <div className={cn('relative overflow-hidden bg-white', className)}>
      {/* Blur placeholder */}
      {isLoading && (
        <img
          src={thumbnailUrl}
          alt=""
          className="absolute inset-0 w-full h-full object-contain blur-lg scale-110 transform"
          aria-hidden="true"
          loading="eager"
          decoding="async"
        />
      )}
      
      {/* Main image */}
      <img
        src={optimizedSrc}
        alt={alt}
        sizes={sizes}
        srcSet={srcSet}
        loading={priority ? 'eager' : 'lazy'}
        decoding="async"
        onLoad={() => setIsLoading(false)}
        className={cn(
          'w-full h-full object-contain transition-opacity duration-300',
          isLoading ? 'opacity-0' : 'opacity-100'
        )}
        {...props}
      />
    </div>
  );
};

export default OptimizedImage;