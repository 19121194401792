import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Toaster } from 'sonner';
import Layout from './components/layout/Layout';
import { ScrollManager } from './components/ScrollManager';
import LoadingSpinner from './components/ui/LoadingSpinner';
import Hero from './components/Hero';
import FeaturedProducts from './components/FeaturedProducts';
import NewArrivals from './components/NewArrivals';

// Lazy load components
const Login = React.lazy(() => import('./pages/admin/Login'));
const Dashboard = React.lazy(() => import('./pages/admin/Dashboard'));
const Orders = React.lazy(() => import('./pages/admin/Orders'));
const AddProduct = React.lazy(() => import('./pages/admin/AddProduct'));
const EditProduct = React.lazy(() => import('./pages/admin/EditProduct'));
const ProductList = React.lazy(() => import('./pages/admin/ProductList'));
const Settings = React.lazy(() => import('./pages/admin/Settings'));
const AuthGuard = React.lazy(() => import('./components/auth/AuthGuard'));
const Shop = React.lazy(() => import('./pages/Shop'));
const ProductDetail = React.lazy(() => import('./pages/ProductDetail'));
const Shipping = React.lazy(() => import('./pages/Shipping'));
const Checkout = React.lazy(() => import('./pages/Checkout'));
const ManualCheckout = React.lazy(() => import('./pages/ManualCheckout'));
const CheckoutSuccess = React.lazy(() => import('./pages/CheckoutSuccess'));
const ContactCard = React.lazy(() => import('./pages/ContactCard'));

const LoadingFallback = () => (
  <div className="min-h-screen flex items-center justify-center">
    <LoadingSpinner />
  </div>
);

const App = () => {
  return (
    <Suspense fallback={<LoadingFallback />}>
      <ScrollManager />
      <Toaster position="top-center" />
      <Routes>
        {/* Admin Routes */}
        <Route path="/admin/login" element={<Login />} />
        <Route element={<AuthGuard />}>
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/orders" element={<Orders />} />
          <Route path="/admin/products" element={<ProductList />} />
          <Route path="/admin/products/new" element={<AddProduct />} />
          <Route path="/admin/products/edit/:id" element={<EditProduct />} />
          <Route path="/admin/settings" element={<Settings />} />
        </Route>

        {/* Public Routes */}
        <Route path="/" element={
          <Layout>
            <div className="space-y-0">
              <Hero />
              <FeaturedProducts />
              <NewArrivals />
            </div>
          </Layout>
        } />
        
        <Route path="/shop" element={
          <Layout>
            <Shop />
          </Layout>
        } />
        
        <Route path="/shop/:category" element={
          <Layout>
            <Shop />
          </Layout>
        } />
        
        <Route path="/product/:id" element={
          <Layout>
            <ProductDetail />
          </Layout>
        } />
        
        <Route path="/shipping" element={
          <Layout>
            <Shipping />
          </Layout>
        } />

        <Route path="/contact-card" element={
          <Layout>
            <ContactCard />
          </Layout>
        } />

        <Route path="/checkout" element={
          <Layout>
            <Checkout />
          </Layout>
        } />

        <Route path="/checkout/manual" element={
          <Layout>
            <ManualCheckout />
          </Layout>
        } />

        <Route path="/checkout/success" element={
          <Layout>
            <CheckoutSuccess />
          </Layout>
        } />

        {/* 404 Route */}
        <Route path="*" element={
          <div className="min-h-screen flex items-center justify-center bg-gray-50">
            <div className="text-center">
              <h1 className="text-3xl font-serif mb-4">Page Not Found</h1>
              <button 
                onClick={() => window.location.href = '/'}
                className="inline-block px-6 py-3 bg-black text-white rounded-md hover:bg-gray-800"
              >
                Return Home
              </button>
            </div>
          </div>
        } />
      </Routes>
    </Suspense>
  );
};

export default App;